define(['app'], (app) => {
  const PowerReviewQASnippet = () => {

    const component = {};

    const COMPONENT_ID_PREFIX = 'pr-qa-snippet-'

    const _subscribeChannels = {
      variantChange: 'productReviewStars/update',
    };

    const _init = (element) => {
      component.element = element;
      component.subscribe();
    };

    const _subscribe = () => {
      app.subscribe(component.subscribeChannels.variantChange, function (sku) {
        component.updateComponentId(sku);
      });
    };

    const _updateComponentId = (sku) => {
      component.element.id = `${COMPONENT_ID_PREFIX}${sku}`;
    }

    component.init = _init;
    component.subscribe = _subscribe;
    component.subscribeChannels = _subscribeChannels;
    component.updateComponentId = _updateComponentId;

    return component;
  };
  return PowerReviewQASnippet;
});
